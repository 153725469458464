.login-box {
  width: 400px;
}

.chat-main-box {
  display: flex;
  flex-direction: row;
}

@media (max-width: 375px) {
  .login-box {
    width: 300px;
    padding-top: 20px;
  }
  .chat-main-box {
    display: flex;
    flex-direction: column;
  }
}

.image-container {
  position: relative;
  margin: 10px;
  width: 200px;
  height: 200px;
  padding-right: unset;
  padding-left: unset;
}

.image {
  width: 200px;
  height: 200px;
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.button {
  margin: 5px;
}

.image-container:hover .overlay {
  display: flex;
  text-align: center;
}

.btn-admin-sm{
  padding: 4px 8px 4px !important;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: white;
}

.button-container {
  position: relative;
  padding-bottom: 60px;
}

.fixed {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 22%;
  left: 10%;
}

.static {
  position: static;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ads-div {
  max-height: 200px;
  max-width: 100%;
  box-sizing: border-box;
}

.ads-div * {
  max-height: inherit;
  width: 100%;
  box-sizing: border-box;
}
