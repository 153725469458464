*{}

.p-0{
	padding:0px;
}
.p-l-0{
	padding-left:0px;
}
.p-r-0{
	padding-right:0px;
}
.p-t-0{
	padding-top:0px;
}
.p-b-0{
	padding-bottom:0px;
}

.p-5{
	padding:5px;
}
.p-l-5{
	padding-left:5px;
}
.p-r-5{
	padding-right:5px;
}
.p-t-5{
	padding-top:5px;
}
.p-b-5{
	padding-bottom:5px;
}

.p-10{
	padding:10px;
}
.p-l-10{
	padding-left:10px;
}
.p-r-10{
	padding-right:10px;
}
.p-t-10{
	padding-top:10px;
}
.p-b-10{
	padding-bottom:10px;
}

.p-15{
	padding:15px;
}
.p-l-15{
	padding-left:15px;
}
.p-r-15{
	padding-right:15px;
}
.p-t-15{
	padding-top:15px;
}
.p-b-15{
	padding-bottom:15px;
}

.p-20{
	padding:20px;
}
.p-l-20{
	padding-left:20px;
}
.p-r-20{
	padding-right:20px;
}
.p-t-20{
	padding-top:20px;
}
.p-b-20{
	padding-bottom:20px;
}

.p-25{
	padding:25px;
}
.p-l-25{
	padding-left:25px;
}
.p-r-25{
	padding-right:25px;
}
.p-t-25{
	padding-top:25px;
}
.p-b-25{
	padding-bottom:25px;
}

.p-30{
	padding:30px;
}
.p-l-30{
	padding-left:30px;
}
.p-r-30{
	padding-right:30px;
}
.p-t-30{
	padding-top:30px;
}
.p-b-30{
	padding-bottom:30px;
}


/**

**/
.m-0{
	padding:0px;
}
.m-l-0{
	margin-left:0px;
}
.m-r-0{
	margin-right:0px;
}
.m-t-0{
	margin-top:0px;
}
.m-b-0{
	margin-bottom:0px;
}

.m-5{
	padding:5px;
}
.m-l-5{
	margin-left:5px;
}
.m-r-5{
	margin-right:5px;
}
.m-t-5{
	margin-top:5px;
}
.m-b-5{
	margin-bottom:5px;
}

.m-10{
	padding:10px;
}
.m-l-10{
	margin-left:10px;
}
.m-r-10{
	margin-right:10px;
}
.m-t-10{
	margin-top:10px;
}
.m-b-10{
	margin-bottom:10px;
}

.m-15{
	padding:15px;
}
.m-l-15{
	margin-left:15px;
}
.m-r-15{
	margin-right:15px;
}
.m-t-15{
	margin-top:15px;
}
.m-b-15{
	margin-bottom:15px;
}

.m-20{
	padding:20px;
}
.m-l-20{
	margin-left:20px;
}
.m-r-20{
	margin-right:20px;
}
.m-t-20{
	margin-top:20px;
}
.m-b-20{
	margin-bottom:20px;
}

.m-25{
	padding:25px;
}
.m-l-25{
	margin-left:25px;
}
.m-r-25{
	margin-right:25px;
}
.m-t-25{
	margin-top:25px;
}
.m-b-25{
	margin-bottom:25px;
}

.m-30{
	padding:30px;
}
.m-l-30{
	margin-left:30px;
}
.m-r-30{
	margin-right:30px;
}
.m-t-30{
	margin-top:30px;
}
.m-b-30{
	margin-bottom:30px;
}

.m-35{
	padding:35px;
}
.m-l-35{
	margin-left:35px;
}
.m-r-35{
	margin-right:35px;
}
.m-t-35{
	margin-top:35px;
}
.m-b-35{
	margin-bottom:35px;
}

.m-50{
	padding:50px;
}
.m-l-50{
	margin-left:50px;
}
.m-r-50{
	margin-right:50px;
}
.m-t-50{
	margin-top:50px;
}
.m-b-50{
	margin-bottom:50px;
}


.m-80{
	padding:80px;
}
.m-l-80{
	margin-left:80px;
}
.m-r-80{
	margin-right:80px;
}
.m-t-80{
	margin-top:80px;
}
.m-b-80{
	margin-bottom:80px;
}

.m-100{
	padding:100px;
}
.m-l-100{
	margin-left:100px;
}
.m-r-100{
	margin-right:100px;
}
.m-t-100{
	margin-top:100px;
}
.m-b-100{
	margin-bottom:100px;
}

.m-115{
	padding:115px;
}
.m-l-115{
	margin-left:115px;
}
.m-r-115{
	margin-right:115px;
}
.m-t-115{
	margin-top:115px;
}
.m-b-115{
	margin-bottom:115px;
}

.mnagativ-28{
	margin-top:-28px;
}



@font-face {
    font-family: Raleway-Bold;
    src: url('fonts/Raleway-Bold.ttf');
}
@font-face {
    font-family: 'Raleway-Medium';
    src: url('fonts/Raleway-Medium.ttf');
}
@font-face {
    font-family: 'Raleway-Regular';
    src: url('fonts/Raleway-Regular.ttf');
}
@font-face {
    font-family: 'Raleway-SemiBold';
    src: url('fonts/Raleway-SemiBold.ttf');
}
@font-face {
    font-family: 'Raleway-Light';
    src: url('fonts/Raleway-Light.ttf');
}

p{
	line-height:28px;
}

h1, h2, h3, h4, h5, h6{
	font-family: Raleway-Bold;
}
h1{
	font-size:35px;
}
h3{
	font-size:24px;
}
textarea.form-control,
input.form-control{
	padding-left: 15px !important;
    padding-right: 15px !important;
}
textarea.form-control{
	height:80px;
}
.dtp > .dtp-content{
	max-height:100%;
	overflow: auto;
}
.dtp-select-year-range{
	width:auto;
	margin-top: 15px !important;
    margin-bottom: 15px !important;
}
.dtp-select-year-range:hover{
	background:#41c476;
}
.dtp div.dtp-picker-month {
    padding-bottom: 10px !important;
	padding-top: 10px !important;
}
.year-picker-item.active{
	color:#41c476;
}
.dtp div.dtp-picker{
	padding:0px;
}

.dtp div.dtp-actual-num{
	font-size: 35px;
    line-height: 50px;
    font-weight: 600;
    width: 100%;
    display: inline-block;
}
.dtp table.dtp-picker-days tr > td > a, .dtp .dtp-picker-time > a{
	padding: 9px 9px;
}
.dtp .dtp-buttons{
	text-align: center;
    padding: 12px;
    border-top: 1px solid #dedede;
}
.dtp table.dtp-picker-days tr > td > a.selected{
	background:#41c476;
}
.dtp div.dtp-date, .dtp div.dtp-time{
	background:#41c476;
}
.dtp > .dtp-content > .dtp-date-view > header.dtp-header {
    background: #37b76b;
}
.dtp .dtp-close{
	top:10px;
}
.dtp table.dtp-picker-days tr > td > a {
    width: 33px;
    display: inline-block;
    vertical-align: middle;
    height: 33px;
    line-height: 15px;
}
.btn:before,
.btn{
	border-radius:40px !important;
	text-transform: capitalize;
}

.btn:before{
	display:none !important;
}
.btn-info:hover{
	background:#41c476;
}
.btn-xs, .btn-small{
	font-size: 10px;
	font-weight: normal;
}
.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn),
.form-control:focus{
	box-shadow:none !important;
}
.cus-select-drop .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn),
.cus-select-drop .form-control .bootstrap-select{
	width:100%;
	margin-bottom:0px !important;
}
.cus-select-drop .bootstrap-select>.dropdown-toggle.bs-placeholder,
.cus-select-drop .form-control .bootstrap-select .btn{
	font-weight:normal;
	font-family: 'Raleway-Regular';
	text-transform:capitalize;
}
.slider-form .cus-select-drop .bootstrap-select>.dropdown-toggle.bs-placeholder:active, 
.slider-form .cus-select-drop .bootstrap-select>.dropdown-toggle.bs-placeholder:focus, 
.slider-form .cus-select-drop .bootstrap-select>.dropdown-toggle.bs-placeholder:hover{
	background:#ffffff;
}

.cus-select-drop .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) > .btn:not(:disabled):not(.disabled), 
.cus-select-drop .navbar-toggler:not(:disabled):not(.disabled), .page-link:not(:disabled):not(.disabled), 
.cus-select-drop .form-control .bootstrap-select > .btn:not(:disabled):not(.disabled), 
.cus-select-drop .navbar-toggler:not(:disabled):not(.disabled), .page-link:not(:disabled):not(.disabled), summary{
	font-weight:normal;
	font-family: 'Raleway-Regular';
	text-transform:capitalize;
	padding-left:15px;	
}
.slider-form .cus-select-drop .bootstrap-select>.dropdown-toggle.bs-placeholder,
.slider-form .cus-select-drop .form-control .bootstrap-select .btn,
.slider-form .cus-select-drop .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) > .btn:not(:disabled):not(.disabled), 
.slider-form .cus-select-drop .navbar-toggler:not(:disabled):not(.disabled), .page-link:not(:disabled):not(.disabled), 
.slider-form .cus-select-drop .form-control .bootstrap-select > .btn:not(:disabled):not(.disabled), 
.slider-form .cus-select-drop .navbar-toggler:not(:disabled):not(.disabled), .page-link:not(:disabled):not(.disabled), summary{
	background:none;
	border:1px solid #ffffff !important;
	color:#ffffff;
}
.slider-form .form-group h4{
	color:#ffffff;
	margin-bottom:0px;
}
.list-group-item.active{
	background:#41c476;
	border-color:#41c476;
}
.list-group-item.active a{
	color:#ffffff;
}
.border-top1gray{
	border-top:1px solid #dedede;
}
.border-bottom1gray{
	border-bottom:1px solid #dedede;
}
.border-top2gray{
	border-top:2px solid #dedede;
}
.border-bottom2gray{
	border-bottom:2px solid #dedede;
}
.border-top1white{
	border-top:1px solid #ffffff;
}
.bordernone{
	border:none;
}
.box-shadow05{
	box-shadow: 0px 10px 30px rgba(0,0,0,0.05);
}
.mrid i:before{
	content:url(images/ring.png);
}
.lisqr{
	list-style: square;
    margin-left: 20px;
}
.lisqr li{
	margin-bottom:10px;
}

.datepicker.dropdown-menu{
	background: #000000;
    border-radius: 0px;
}
.slider-form .form-group .custom-checkbox{
	color:#ffffff;
}

.align-radios .custom-radio{
	display:inline-block;
	vertical-align:middle;
}
.full-width{
	width:100%;
}

.pr-bg-border{
	
}
.dash-prlnks{
	
}
.dash-prlnks a{
	
}

.align-radios .custom-checkbox{
	display:inline-block;
	vertical-align:middle;
}

.datepicker table tr td.active:hover, .datepicker table tr td.active:hover:hover,
.datepicker table tr td.active:active, .datepicker table tr td.active:hover:active,
.datepicker table tr td.active.active, .datepicker table tr td.active:hover.active{
	background:#41c476;
}
.datepicker.dropdown-menu th, .datepicker.dropdown-menu td{
	padding: 8px 10px;
	border-radius: 0px;
	color:#ffffff;
	text-shadow: none !important;
}

.datepicker table tr td.old, .datepicker table tr td.new {
    color: #777;
}
.datepicker-dropdown:after,
.datepicker-dropdown:before{
	border-bottom-color:#000000;
}
.datepicker-dropdown.datepicker-orient-bottom:after,
.datepicker-dropdown.datepicker-orient-bottom:before{
	border-top-color:#000000;
}
.react-datepicker-wrapper {
    display: block !important;
}

.popover{
	border-radius:0px !important;
}
.btn-default.clockpicker-button{
	border-radius: 0 !important;
}
.clockpicker-button:hover{
	background:#41c476;
}
.header-section{
	background:#ffffff;
}
.header-top {
	border-bottom: 1px solid #dedede;
}
.header-links a{
	color:#333333;
	font-family: 'Raleway-Medium';
}
.header-social a{
	color:#333333;
	font-family: 'Raleway-Medium';
}
.header-links a i{
    vertical-align: middle;
    vertical-align: -webkit-baseline-middle;
	vertical-align: -moz-middle-with-baseline;
	float:none;
	display:inline-block;
}
.header-links a i:before{
	vertical-align: top;
}
.header-links a i.fa-phone:before{
	content:url(images/phone-icon.png);
}
.header-links a i.fa-envelope:before{
	content:url(images/mail-icon.png);
}
.header-links a i.fa-sign-in:before{
	content:url(images/login-icon.png);
}
.header-links a i.fa-support:before{
	content:url(images/helpsup-icon.png);
}
.header-bottom{
	box-shadow: 0px 10px 30px rgba(0,0,0,0.05);
}
.section-title h1{
	color:#333333;
	margin-bottom:20px;
}
.user-head-icon{
	margin-left:15px;
}
.user-head-icon span{
	margin-left:5px;
}

.user-head-icon img{
	
}
.user-head-icon .dropdown-menu{
	min-width:185px;
	top: 100% !important;
}
.head-content .dropdown-menu li .btn {
	padding:0px;
	line-height: 40px;
    text-align: center;
}
.head-content .dropdown-menu{
	border-radius:0px !important;
}

 .form-material .form-group {
	overflow: visible;
}
.reg-select-drop{
	
}
.reg-select-drop .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn),
.reg-select-drop .form-control .bootstrap-select{
	background-image: linear-gradient(#41c476,#41c476),linear-gradient(#e9ecef,#e9ecef);
}
.reg-select-drop .bootstrap-select>.dropdown-toggle.bs-placeholder, 
.reg-select-drop .form-control .bootstrap-select .btn{
	padding-left:15px;
	color:#2C3E50;
}
.btn.active, .btn:active{
	box-shadow:none !important;
}
.reg-select-drop .btn,
.reg-select-drop .btn:focus, 
.reg-select-drop .btn:hover{
	color: #2C3E50;
    font-family: 'Raleway-Regular';
    font-weight: normal;
    padding-left: 15px;
	text-transform: capitalize;
}
.login-drop{
	
}

.login-drop .dropdown-menu {
    left: initial !important;
    right: 0px;
    border-radius: 0;
    top: 7px !important;
    border: none;
    box-shadow: 0px 10px 40px rgba(0,0,0,0.08);
    width: 100%;
    min-width: 280px;
    padding: 15px;
}
.bootstrap-select.btn-group .dropdown-menu{
    border-radius: 0;
    border: none;
    box-shadow: 0px 10px 40px rgba(0,0,0,0.08);
    width: 100%;
}
.header-links .login-drop .dropdown-menu a{
	margin-right:0px;
}
.lform-title{
	margin-bottom: 15px;
}
.lform-title h3{
	border-bottom: 5px solid #41c476;
	display:inline-block;
	padding-bottom:10px;
}
.lform-head input{
	border-bottom: 1px solid #dedede !important;
    border-radius: 0;
}
.lform-head input:focus{
	border-color:#41c476 !important;
}
.lform-social-login a{
	margin-right:0;
	display:inline-block;
	float:none;
}
.lform-social-login a i{
	height: 30px;
    width: 30px;
    text-align: center;
    margin-right: 0;
    background: #dedede;
    color: #000;
}
.lform-head .form-group i{
	display:inline-block;
	float:none;
	vertical-align:middle;
}
.lform-forgot{
	margin-top:15px;
	text-align:center;
}
.lform-forgot a{
	display:inline-block;
	float:none;
	text-decoration:underline;
}
.btn-facebook{
	background:#3B5998;
	margin:2Px;
	line-height: 12px;
}
.btn-googleplus{
	background:#dd4b39;
	margin:2Px;
	line-height: 12px;
}
.btn-twitter{
	background:#1dcaff;
	margin:2Px;
	line-height: 12px;
}



.hero-content  .button a:hover{
	background:#ffffff;
	color:#293155;
}
.hero-content .content1 p{
	color:#ffffff;
	line-height:28px;
	font-size:16px;
	padding-right: 40px;
}
 
.slider-form-section{
	background:rgba(0,0,0,0.5);
	box-shadow: 0px 2px 40px rgba(0,0,0,0.35);
}

.slider-form-section h3{
	color:#ffffff;
}
.slider-form{
	
}
.hero-item .hero-content .btn{
	margin-top: 40px;
}
.hero-image{
	padding-top:77px;
	padding-bottom:77px;
}
.slider-form input{
	background:none;
	border:1px solid #ffffff !important;
	border-radius:0px !important;
	color:#ffffff;
}
.slider-form input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #ffffff;
}
.slider-form input::-moz-placeholder { /* Firefox 19+ */
  color: #ffffff;
}
.slider-form input:-ms-input-placeholder { /* IE 10+ */
  color: #ffffff;
}
.slider-form input:-moz-placeholder { /* Firefox 18- */
   color: #ffffff;
}
.slider-form .btn{
	background:#ffffff;
	color:#000000;
	border-radius:0px !important;
	font-family: 'Raleway-SemiBold';
	text-transform:uppercase;
	font-size:16px;
}
.btn.btn-orange{
	background:#ff714e;
	color:#ffffff;
	min-width:177px;
	text-align:center;
}
.btn.btn-green{
	background:#41c476;
	color:#ffffff;
	min-width:177px;
	text-align:center;
}

.custom-select-reg{
	border-radius: 0px;
	border:1px solid #ffffff !important;
	color:#ffffff;
	background-color: transparent;
	height: 45px !important;
}

.custom-select-prof{
	border-radius: 0px;
	color:#6c7984;
	padding-right: 15px !important;
	padding-left: 15px !important;
}

.three-step{
	width: 100%;
    float: none;
    display: inline-block;
    box-shadow: 5px 10px 50px rgba(0,0,0,0.05);
    padding: 0px 40px 40px;
}
.three-step .content{
	padding-bottom:0px;
}

.three-step .content h3{
	margin-bottom:20px;
}
.three-step .content p{
	color:#999999;
}

.icono{
	display: inline-block;
	margin-top: -30px;
    box-shadow: 0px 10px 40px rgba(0,0,0,0.1);
    width: auto;
    border-radius: 50%;
	margin-bottom:26px;
}
.icono span{
	background: #41c476;
    height: 70px;
    width: 70px;
    text-align: center;
    display: inline-block;        
    border-radius: 50%;
    border: 5px solid #ffffff;
}
.three-step:hover .icono span{
	background:#ff714e;
}
.icono span h3{
	line-height: 60px;
	color: #ffffff;
}

.counter-area{
	padding:77px 0px;
}
.counter h1{
	color:#ffffff;
	font-size:35px;
}
.counter h1:after{
	content:'+';
}
.counter h6{
	font-size:28px;
	color:#ffffff;
	text-transform: capitalize;
	font-family: 'Raleway-SemiBold';
	margin-top:30px;
}
.form-footer{
	
}
.form-footer input,
.form-footer textarea{
	border-radius:4px !important;
}
.form-footer input{
	min-height:50px;
}
.form-footer .btn{
	background:#ff714e;
	color:#ffffff;
	border-radius:4px !important;
	font-size:18px;
	text-transform:uppercase;
	font-family: 'Raleway-SemiBold';
}
.form-footer .btn:hover{
	background:#41c476;
	color:#ffffff;
}
.form-footer .btn:not([disabled]):not(.disabled).active, 
.form-footer .btn:not([disabled]):not(.disabled):active{
	box-shadow:none !important;
	background:#41c476;
	border-color: #41c476;
	box-shadow: none !important;
}
.btn-info:not([disabled]):not(.disabled).active, 
.btn-info:not([disabled]):not(.disabled):active, 
.show>.btn-info.dropdown-toggle{
	box-shadow:none !important;
}
.footer-widget p{
	color:#ffffff;
	line-height:28px;
}
.owl-carousel .owl-item img{
	/*width:auto;*/
	border-radius: 50%;
    height: 100%;
    max-height: 65px;
    max-width: 65px;
    width: 100%;
}
.owner-sec{	
      margin-top: 45px;
}
.owner-sec .onr-thumb{
	display:inline-block;
	width:auto;
	float:none;
	vertical-align:middle;
}
.owner-sec .onr-detail{
	display:inline-block;
	width:auto;
	float:none;
	vertical-align:middle;
	margin-left: 15px;
}
.owner-sec .onr-detail h4{
	font-size:18px;
	font-family: 'Raleway-SemiBold';
	margin-bottom: 0;
	text-align:left;
}
.owner-sec .onr-detail span{
	font-family: 'Raleway-Light';
	font-size:14px;
	text-align:left;
}
.mission-section h1{
	margin-bottom:50px;
}
.owl-carousel .owl-item{
	text-align:center;
}
.owl-item .single-item{
	width:100%;
	display:inline-block;
	max-width:810px;
}

.custom-checkbox .custom-control-label::before{
	border-radius: 0;
}

.breadcrumb-bg{
	padding: 80px 0 50px;
    margin-top: 150px;
	background:#323232;
}
.section-title {
    font-size: 40px;
    letter-spacing: 1px;
}
.site-breadcumb .section-title {
    font-size: 30px;
	text-transform: capitalize;
	color:#ffffff;
}
.light-font {
    font-weight: 300;
}
.breadcrumb-menubar {
    background: transparent none repeat scroll 0 0;
    margin: 7px 0 0;
    padding: 0;
}
.breadcrumb>li {
    display: inline-block;
}
.breadcrumb-menubar > li {
    text-transform: uppercase;
    font-weight: 500;
    color: #a8a7a4;
    width: 100%;
    font-size: 12px;
}
.breadcrumb-menubar > li > a {
    color: #a8a7a4;
}
.breadcrumb-menubar > li > a:after {
    content: "/";
    color: #a8a7a4;
    padding: 0 3px;
}
.contactp-info {
    padding: 10px;
    text-align: left !important;
    float: left;
    width: 100%;
    margin-bottom: 50px;
}
.contactp-info .icono{
	float: none;
	display:inline-block;
	margin-top:0px;
	margin-bottom:0px;
}
.contactp-info .icono span{
	border-radius:0px;
}
.contactp-info .content{
	float: none;
	display:inline-block;
    margin-left: 15px;
    width: 75%;
	vertical-align:middle;
}
.contactp-info .content h3{
	margin-bottom:0px;
}
.contact-pform {
	display: inline-block;
    float: none;
    width: 100%;
    background: #ffffff;
	box-shadow: 5px 10px 50px rgba(0,0,0,0.05);
}
.contact-pform input,
.contact-pform textarea{
	border:1px solid #dedede !important;
	border-radius:0px !important;
}
.reg-form{
	width: 100%;
    max-width: 680px;
    margin: 0 auto;
}
.reg-select-drop .btn,
.bootstrap-select.btn-group .dropdown-toggle .filter-option{
	font-size: 14px;
}

/*=================================

	 REGESTER -- FORM -- PAGE

=================================*/
.regester-form-section{
	padding-top:190px;
}

.stepwizard-row {
    margin-bottom: 30px;
    overflow: hidden;
    padding-left: 0px;
    counter-reset: step;
}

.stepwizard {
    display: table;
    width: 100%;
    position: relative;
}

.stepwizard-step button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}

.stepwizard-row:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #ccc;
    z-order: 0;

}

.stepwizard-step {
    list-style-type: none;
    color: #686868;
    font-size: 13px;
    /* width: 33.33%; */
    width: 16.5%;
    float: left;
    position: relative;
	text-align:center;
}

.stepwizard-step a.btn.btn-default {
	/*width: 30px;
	height: 30px;*/
	text-align: center;
	padding: 6px 0;
	font-size: 12px;
	border-radius: 50%;
    width: 40px;
    line-height: 28px;
    display: block;
    font-size: 10px;
    color: #fff;
    background: #686868;
    border-radius: 100%;
    position: relative;
    z-index: 10;
    margin: 0 auto 0px auto;
}
.stepwizard-step a.btn.btn-primary{
		background:#41c476;
	}
.form-material .bootstrap-select.btn-group .dropdown-menu{
    top: 0 !important;
	transform: translate3d(0px, 0px, 0px) !important;
	max-height: 250px !important;
}
.bootstrap-select.btn-group .dropdown-menu.inner{
	/*max-height: 350px !important;
    overflow: auto !important;*/
}

.box-shadow02{
    box-shadow: 0px 2px 20px rgba(0,0,0,0.2);
}

/*=================================

	 REGESTER -- FORM -- PAGE

=================================*/




/*=================================

		PROFILE -- PAGE

=================================*/

.cusp-icon-left {
    background: #41c476;
    color: #fff;
    text-align: center;
    height: 48px;
    width: 48px;
    line-height: 52px;
    border-radius: 50%;
    font-size: 28px;
    box-shadow: 0px 5px 20px rgba(0,0,0,0.1);
	    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.profile-header-info p{
	margin-bottom:0px;
}
.profile-header-img{
	width:100%;
	display:inline-block;
	margin-right:20px;
	vertical-align:middle;
	max-width:120px;
	background:#ffffff;
	border-radius:50%;
	height:120px;
	text-align:center;
	line-height:120px;
	position: relative;
}
.profile-header-info{
	width: 100%;
    display: inline-block;
    margin-right: 0px;
    float: none;
    max-width: 767px;
    padding-top: 0px;
}
.my-profile .profile-header-info{
	max-width:100%;
	margin-top:25px;
}
.my-profile .profile-header-img{
	margin-right:0px;
}
.vecontact{
	width: 100%;
    max-width: 150px;
}
.profile-header-info a.btn {
    /*font-size: 19px;
    padding: 0;
    border-radius: 50% !important;
    height: 44px;
    width: 44px;
    line-height: 43px;
    text-align: center;*/
	font-size: 14px;
    padding: 5px 10px;
    line-height: 28px;
    text-align: center;
    display: inline-block;
	font-weight:normal;
}
.prfe-head-info a.btn{
	background: none;
}
.profiletimeline .sl-item{
	
}
.profiletimeline .sl-item:hover .cusp-icon-left{
	background:#ff714e;
	    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.sidebar-right{
	
}
.sidebar-right .card{
	
}
.sidebar-right .card .card-body .chatonline{
	margin-top:0px;
}
.sidebar-right .card .card-body .chatonline h4{
	margin-bottom: 20px;
    border-bottom: 1px solid #dedede;
    padding-bottom: 15px;
}
.sidebar-right .card .card-body .chatonline li{
	display: inline-block;
    width: 100%;
    border-bottom: 1px solid #dedede;
}
.sidebar-right .card .card-body .chatonline li:last-child{
	border-bottom:none;
}
.sidebar-right .card .card-body .chatonline li:last-child a{
	padding-bottom:0px;
}
.chatonline li a span {
	float:left;
	width: 100%;
    max-width: 240px;
}
.col-md-3 .chatonline li a span{
	max-width:72%;
}
.chatonline li a span small{
	line-height:18px;
}


.prohead-hidnicon {
    display: inline-block;
    height: 120px;
    width: 120px;
    position: absolute;
    left: 0;
    top: 0;
}
.prohead-hidnimg img{
	filter: blur(10px);
}
.random-img {
    display: table-cell;
    vertical-align: middle;
}
.random-text {
    display: table-cell;
    padding-left: 20px;
	text-align:left;
    vertical-align: middle;
}
.random-text h5{
	margin-bottom:0px;
}
.random-prod .divider {
    background-color: #eee;
    display: inline-block;
    height: 2px;
    width: 20px;
    margin: 0px auto;
}

.cus-rendom-prod{
	margin-top:20px;
}
.item .cus-rendom-prod:first-child{
	margin-top:0px;
}
.random-img{
	padding-top:0px;
}
.cus-rendom-txt{
	
}
.cus-rendom-prod p{
	font-size: 14px;
    line-height: 20px;
    margin-top: 5px;
	margin-bottom: 5px;
}
.cus-prmview{
	
}
.cus-prmview .owl-nav{
	text-align:right;
	margin-top: 20px;
	
}
.cus-prmview .owl-nav .owl-prev{
	display: inline-block;
    border: 1px solid #dedede;
    width: 40px;
    border-radius: 40px 0px 0px 40px;
	text-align:center;
}
.cus-prmview .owl-nav .owl-next{
	display: inline-block;
    border: 1px solid #dedede;
    width: 40px;
    border-radius: 0px 40px 40px 0px;
	text-align:center;
}

/*=================================

		PROFILE -- PAGE

=================================*/

#soft_div {
	margin-top:15px;
}
#soft_div a {
    background: #fff !important;
    color: #0274cb !important;
    border-radius: 0;
    /*height: 20px;
    line-height: 20px;*/
    -webkit-box-shadow: 1px 3px 3px rgba(0,0,0,.1);
    -moz-box-shadow: 1px 3px 3px rgba(0,0,0,.1);
    box-shadow: 1px 3px 3px rgba(0,0,0,.1);
    border: solid 1px #d8d8d8;
    margin: 5px 5px;
    font-size: 13px;
	padding:7px;
}
.minimal-faq .card .card-header {
    background: #fff;
    padding: 0px 0;
    margin-top: 0px;
}
.minimal-faq .card .card-header a.link{
	padding:20px;
	display:inline-block;
	width:100%;
}

.minimal-faq .card .card-header a.link i.mdi-plus-box,
.minimal-faq .card .card-header a.link.collapsed i.mdi-minus-box{
	display:none;
}
.minimal-faq .card .card-header a.link.collapsed i.mdi-plus-box,
.minimal-faq .card .card-header a.link i.mdi-minus-box{
	display:block;
}
.acrd-onlight{
	display:inline-block;
	height:5px;
	width:5px;
	background:#41c476;
	border-radius:50%;
	vertical-align: middle;
}

.sidebar-right .card .card-body a{
	display:inline-block;
	float:none;
	width:100%;
}
.search-profiles{
	display: inline-block;
    width: 100%;
    float: left;
}
.search-profile-box{
	border: 1px solid #d8d8d8;
	padding: 22px 18px;
	background: #FFF;
	-webkit-box-shadow: 1px 3px 3px rgba(0,0,0,.1);
	-moz-box-shadow: 1px 3px 3px rgba(0,0,0,.1);
	box-shadow: 1px 3px 3px rgba(0,0,0,.1);
	display: inline-block;
    width: 100%;
    float: left;
	margin-top:15px;
}
.search-profile-heading{
	float:left;
	width:100%;
}
.prthumb{
	float:left;
	margin-right: 15px;
}
.search-profile-heading img{
	max-height:90px;
	float:left;
}
.search-profile-info{
	width:100%;
	float:left;
}
.search-profile-info span{
	width: 50%;
    display: inline-block;
    line-height: 28px;
    margin-bottom: 10px;
    float: left;
}

.varified-acount {
    width: 100%;
    margin: 0 0px;
    border: 3px solid #dedede;
    border-radius: 3px;
    display: inline-block;
    padding: 0px;
}
.badge_bg {
    background: #f1f1f2;
    height: 46px;
    padding: 0 12px;
}
.recent_join, .badge_no_1, .badge_no_2, .badge_no_3 {
    background: url(https://img2.shaadi.com/assests/2016/images/small-badge-sprite-v3.png) no-repeat left -141px;
    margin: -14px 0 0 2px;
    width: 50px;
    height: 60px;
    float: left;
}
.badge_no_3 {
    background-position: left -287px;
}
#trust_badge_profile_tooltip, .trust_badge_tt {
    position: relative;
    float: left;
}
.member_recent_hd, .member_trust_hd {
    font: normal 16px/21px arial;
    color: #72727d;
    text-shadow: 0 1px 0 #fff;
    float: left;
}
.member_trust_hd {
    padding: 10px 8px 0;
}
.member-head {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    padding-bottom: 10px;
    /* border-bottom: 1px solid #dedede; */
}
.member_header_wrapper {
    padding: 20px 10px 10px;
    display: inline-block;
    width: 100%;
}

.member_left_wrap {
    float: left;
    width: 250px;
    padding: 0 10px;
    margin: 5px 0;
}
.ver-badge{
    font: normal 14px/21px arial;
}
.mem_badge_tagline {
    font: normal 12px/15px arial;
}
.member_right_wrap {
    float: left;
    width: 38px;
    height: 49px;
}
.icon-contct, .icon-consocal, .icon-idprf {
    background: url(https://img2.shaadi.com/assests/2016/images/small-badge-sprite-v3.png) no-repeat left -435px;
    width: 46px;
    height: 49px;
}
.icon-consocal {
    background-position: left -635px;
}
.icon-idprf{
	 /*background-position: left -560px;*/
}

.primgedit {
	position: absolute;
    line-height: 30px;
    display: inline-block;
    bottom: -5px;
    left: 40%;
    color: #000;
    background: #fff;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    font-size: 20px;
}
.mcodetail{
	
}
.mcodetail .col-md-12 strong{
	display: inline-block;
    max-width: 170px;
	width: 100%;
	vertical-align: middle;
}
.prflcomplt .progress{
	border-radius:0px;
	height: 19px;
	padding: 2px;
}

.list-group-item{
	border-radius:0px !important;
}

.lstupdt-img{
	float:left;
	width:100%;
	max-width:100px;
}
.lstupdt-img img{
	width: 100%;
    max-height: 70px;
    max-width: 70px;
    border-radius: 50%;
}
.lstupdt-info{
	display: inline-block;
    margin-left: 15px;
	width: 73%;
}
.lstupdt-info p{
	width:100%;
	float:left;
	margin-bottom:0px;
	line-height:20px;
}
.dsall{
	width: 100%;
    display: inline-block;
    padding: 10px;
    background: #dedede;
}
.pop-brnifits{
	
}
.pop-brnifits p{
	/*margin-bottom: 7px;
    border-bottom: 1px solid #dedede;
    padding-bottom: 5px;*/
}
.pop-brnifits p:last-child{
	border:none;
	padding-bottom:0px;
	margin-bottom:0px;
}
.pop-brnifits p i{
	font-size:18px;
}
.cus-rebon-new{
	/*display: inline-block;
    line-height: 100%;
    padding: 2px 8px;
    border-radius: 40px;
	position: absolute;
    left: 0;*/
	display: inline-block;
    line-height: 100%;
    padding: 2px 8px;
    border-radius: 40px;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: normal !important;
}


.notification-col{
	width:100%;
	display:inline-block;
	padding-bottom:20px;
	border-bottom:1px solid #dedede;
	padding:10px 20px;
}
.notification-col:nth-child(2n+1){
	background:#fafafa;
}
.notification-col:first-child{
	
}
.notification-col:last-child{
	border-bottom:none;
	margin-bottom:0px;
}
.notfn-thumb{
	display: inline-block;
    float: none;
    vertical-align: middle;
}
.notfn-thumb figure{
	margin-bottom:0px;
}
.notfn-thumb figure img{
	height:100%;
	max-height:35px;
	border:1px solid #dedede;
	padding:2px;
}
.notfn-info{
	display: inline-block;
    float: none;
    vertical-align: middle;
	margin-left:15px;
}
.notfn-time{
	display: inline-block;
    float: none;
    vertical-align: middle;
	margin-top:5px;
}


.rating { 
  border: none;
  float: left;
}

.rating > input { display: none; } 
.rating > label:before { 
  margin: 5px;
  font-size: 1.25em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}

.rating > .half:before { 
  content: "\f089";
  position: absolute;
}

.rating > label { 
  color: #ddd; 
 float: right; 
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating > input:checked ~ label, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label { color: #FFD700;  } /* hover previous stars in list */

.rating > input:checked + label:hover, /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating > input:checked ~ label:hover ~ label { color: #FFED85;  } 

.cus-profil-list-slides{
	
}

.cus-profil-list-slides .random-img{
	display: inline-block;
	margin-bottom:20px;
}
.cus-profil-list-slides .random-text{
	display: inline-block;
    padding-left: 0;
    text-align: center;
}
.cus-profil-list-slides .cus-rendom-prod{
	padding:20px;
}
.prlist-heading{
	width:100%;
	padding:20px;
	background:#ededed;
}
.cus-profil-list-slides .owl-nav{
	float: none;
    display: inline-block;
    width: 100%;
    text-align: center;
    border-top: 1px solid #dedede;
    padding-top: 25px;
}
.cus-profil-list-slides .random-img img{
	height:200px;
	width:200px;
	max-height: 200px;
	border-radius: 0;
    max-width: 200px;
}

.cus-chaton li a span{
	width:auto;
}


@media only screen and (max-width: 991px) {
.mobile-menu .mean-bar .mean-nav > ul{
	margin-top:50px;
}
.user-head-icon{
		margin-left:0px;
		padding-right:60px;
	}
	.mobile-menu .mean-bar .mean-nav > ul {
		max-height: max-content;
	}
	.mobile-menu, .mobile-menu .mean-bar{
		position: inherit !important;
	}
	.mobile-menu .mean-bar .meanmenu-reveal{
		top: 20px;
		right: 15px !important;
	}
	.user-head-icon .dropdown-menu{
		left: initial !important;
		right: 0;
	}
	.head-content .topbar .top-navbar .navbar-nav .dropdown-menu {
		min-width: 250px;
		left: initial !important;
		right: 0;
	}
}
@media only screen and (max-width: 767px) {
  .hero-item .hero-content {
	  padding: 85px 15px 100px;
	   text-align: center;
	} 
	.hero-item .hero-content .btn{
		margin-left:0px;
	}
	.hero-content .content1 p{
		padding-right:0px;
	}
	.three-step{
		padding-left:0px;
		padding-right:0px;
	}
	.footer-widget p{
		font-size:16px;
	}
	.mission-area-content{
		text-align:center;
	}
	.login-drop .dropdown-menu {
		left: 0px !important;
		right: initial;
	}
	.head-content .topbar{
		width:auto;
	}
}
@media only screen and (max-width: 620px) {
	h1{
		font-size:28px !important;
	}
		.mission-area {
		padding-bottom: 0;
	}
	.stepwizard-step{
		width:100%;
		display:inline-block;
		text-align:left;
		background: #686868;
		margin-bottom:15px;
	}
	
	.stepwizard-step a.btn.btn-default {
		width: 40px;
		display: inline-block;
		border-radius: 0 !important;
	}
	.stepwizard-row:before{
		display:none;
	}
	.stepwizard-step p{
		float: none;
		display: inline-block;
		vertical-align: middle;
		color:#ffffff;
	}
}

@media only screen and (max-width: 480px) {
	.logo img {
		max-width: 160px;
		width: 100%;
	}
	
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color:#000000 !important;
 
}


